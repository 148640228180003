import React from "react";
import { Box, CircularProgress, TableContainer } from "@mui/material";
import { DialogAlert, FilledButton, SuccessDailog } from "../../common";
import { useUpdateShippingItems } from "src/hooks/ManageWorkOrder";
import { useTranslation } from "react-i18next";
import ShippingItems from "../WorkOrderTables/ShippingItems";
import { useQueryClient } from "react-query";
import moment from "moment";

type DialogActionButtonsProps = {
  handleSave: () => void;
  loading: boolean;
  checkedItems: any;
};

const DialogActionButtons = ({
  handleSave,
  loading,
  checkedItems,
}: DialogActionButtonsProps) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: "flex", gap: 30 }}>
      <FilledButton
        sx={{ margin: 0, height: "60px", padding: 2, width: 150 }}
        textProps={{
          fontStyle: "normal normal bold 18px/14px Poppins",
          upperCase: true,
          typographyProps: { sx: { padding: 0 } },
        }}
        onClick={handleSave}
      >
        {loading && <CircularProgress sx={{ color: "#fff" }} size={14} />}{" "}
        {t("Save")}
      </FilledButton>
    </div>
  );
};

type ShippingItemsProps = {
  isOpen: boolean;
  closeDialog: () => void;
  workOrderId: number;
  workOrderItem: any
};

export default function ShippingItemDialog({
  isOpen,
  closeDialog,
  workOrderId,
  workOrderItem
}: ShippingItemsProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [showSuccessDialog, setShowSuccessDialog] = React.useState(false);
  const [checkedItems, setCheckedItem] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);


  const onSuccessCallback = (isSuccess: boolean) => {
    if(isSuccess){
      handleClose();
      setLoading(false);
    }else{
      setLoading(false);
    }
  }

  const { mutate: update } =
  useUpdateShippingItems(onSuccessCallback);




  const handleSave = async () => {
    try {
      const isCore = workOrderItem?.PO_Type === 'Core';
      const items =  isCore ? checkedItems?.[0] : checkedItems;

      const data = isCore ?  {
        shipDate: items?.ShipDate,
        method: items?.Method,
        name: items?.Name,
        companyName: items?.CompanyName,
        streetAddress: items?.StreetAddress,
        streetAddress_secondary: items?.StreetAddress_secondary,
        city: items?.City,
        state: items?.State,
        zip: items?.Zip,
        phone: items?.Phone,
        trackingNumber: items?.TrackingNumber,
        notes: items?.Notes,
        shipped: 1,
        active: items?.Active,
        // workOrderItems:  isCore ? undefined : checkedItems?.WorkOrderItems?.map((el: any) => ({
        //   active: el?.Active,
        //   catalogColor: el?.CatalogColor,
        //   catalogSize: el?.CatalogSize,
        //   catalogStyle: el?.CatalogStyle,
        //   ds_PONumber: el?.DS_PONumber,
        //   id: el?.Id,
        //   inventoryId: el?.InventoryId,
        //   quantity: el?.Quantity,
        //   sku: el?.SKU,
        //   silvaProvided: el?.SilvaProvided,
        //   totalPrice: el?.TotalPrice,
        //   unitPrice: el?.UnitPrice,
        //   woId: el?.WOId
        //  }))
  
      } : {
        updates: items?.map((el: any) => ({
          dropShippingAddressId: el?.Id,
          updateData: {
            shipDate:  moment().format('YYYY-MM-DD'),
            shipped: 1,
            // active: el?.Active,
          //   DS_PONumber: el?.DS_PONumber
          }
        }))
      }

      setLoading(true);
      await update({
          id:  isCore ?  items?.Id : undefined,
          items: {...data},
        });
    } catch (error) {
      setLoading(false);
      console.error("Error in updating:", error);
    }
  };

  const handleChecked = (item: any, isChecked: boolean) => {
    
    if (isChecked) {
      setCheckedItem([...checkedItems, item]);
      
    } else {
      const filteredChecked = checkedItems?.filter((el: any) => item?.Id !== el?.Id)
      setCheckedItem(filteredChecked);
    }
  };

  const handleClose = () => {
    queryClient.removeQueries('fromShippingItems');
    setCheckedItem([])
    closeDialog();
  }

  return (
    <>
      <DialogAlert
        isOpen={isOpen}
        handleClose={handleClose}
        dialogTitle={t("Shipping Details")}
        headerWidth={200}
        containerProps={{ maxWidth: "xl", fullWidth: true, fullScreen: true }}
      >
        <TableContainer
          sx={{
            padding: "8px 0px",
            width: "100%",
            height: "80vh",
            overflowY: "auto",
          }}
        >
          <ShippingItems
            workOrderId={workOrderId}
            handleChecked={handleChecked}
            checkedItems={checkedItems}
            isCore={workOrderItem?.PO_Type === 'Core'}
          />
        </TableContainer>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            padding: "16px 0px 16px 0px",
          }}
        >
          <DialogActionButtons
            loading={loading}
            handleSave={handleSave}
            checkedItems={checkedItems}
          />
        </Box>
      </DialogAlert>
      {showSuccessDialog && (
        <SuccessDailog
          SucessNote={t("Shipping items updated successfully!")}
          FlaggedNote={"fdsf"}
          isOpen={showSuccessDialog}
          closeDialog={() => setShowSuccessDialog(false)}
        />
      )}
    </>
  );
}
