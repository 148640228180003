import { CognitoUserPool } from 'amazon-cognito-identity-js'

const poolData = {
  UserPoolId: 'us-east-2_Wb8pmd0BP',
  ClientId: '3drqo6n8kkv0ikostc2t7up5qt',
}

const userPool = new CognitoUserPool(poolData)

export default userPool
